.discreet_mode {
  .dom-renderer {
    filter: blur(100px) !important;
  }
  .blur_on_discreet {
    filter: blur(10px) !important;
  }
}


//.BLUR_FOR_WEBINAR {
//  filter: blur(10px) !important;
//  &.STRONG{
//    filter: blur(40px) !important;
//  }
//  touch-action: none;
//  pointer-events: none;
//}
