.amazon_preview {
    color: var(--color-black);
    font-size: 14px;
    font-weight: 400;
    transition: background-color 0.2s, color 0.2s;
}

.compact_title {
    font-size: 14px;
    font-weight: bold;
    color: var(--color-black);
}

.amazon_preview .title {
    font-size: 19px;
    font-weight: 500;
}

.amazon_preview .title_2 {
    color: rgb(204, 102, 0);
    font-size: 16px;
    font-weight: 500;
}

.amazon_preview .brand {
    color: blue;
}


.amazon_preview .grey {
    color: rgb(85, 85, 85);
}

.amazon_preview .small {
    font-size: 10px;
}

.amazon_preview .red {
    color: rgb(177, 39, 4);
}

.amazon_preview .sale_price {
    font-size: 17px;
}

.amazon_preview .amazon_variation_button {
    border: 1px solid rgb(162, 166, 172);
    border-radius: 2px;
    height: 29px;
    line-height: 19px;
    padding-bottom: 5px;
    padding-left: 11px;
    padding-right: 10px;
    padding-top: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.amazon_preview .amazon_variation_button:hover, .amazon_preview .image_selector:hover,
.amazon_preview .image_selector.selected {
    border: 1px solid rgb(231, 118, 0);
}

.amazon_preview .amazon_variation_button.selected {
    border: 1px solid rgb(231, 118, 0);
    font-weight: bold;
}

.amazon_preview ul.amazon_key_points {
    padding-left: 13px;
}


.amazon_preview .image_selector {
    border: 1px solid rgb(162, 166, 172);
    border-radius: 2px;
}


.amazon_preview .square_title {
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: -0.3px;
}

.amazon_preview .square_price {
    font-size: 19px;
}

.amazon_preview .line_title {
    font-size: 17px;
    line-height: 21px;
}


.amazon_preview a, .amazon_preview a:hover {
    color: inherit;
    text-decoration: none;
}
