$theme-colors: (
        "primary": blue,
);
@import "~bootstrap/scss/bootstrap";

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.variable-root {
  /* dark */
  --color-bg-menu: #081017; /* var(--color-bg-menu); */
  --color-bg-menu-transparent: rgba(8, 16, 23, 0); /* var(--color-bg-menu); */
  --color-bg-global: #142533; /* var(--color-bg-global); */
  --color-bg-global-darker: #0c151d; /* var(--color-bg-global); */
  --color-bg-global-darker2: #000000; /* var(--color-bg-global); */
  --color-bg-global-transparent: rgba(20, 37, 51, 0); /* var(--color-bg-global); */
  --color-text-menu: #8DA99C; /* var(--color-text-menu); */
  --color-text-global: #EDE7D9; /* var(--color-text-global); */
  --color-alt: #AAB391; /* var(--color-alt); */
  --color-shadow-neu-right: rgba(149, 156, 167, 0.05);
  --color-shadow-neu-left: #203b52;


  --color-white: #212121;
  --color-black: #cbcbcb;
  --color-light-red: #721514;
  --color-grey: #626262;
  --color-green: #005d00;
}


.variable-root.light {
  --color-bg-menu: rgba(32, 64, 81, 1); /* var(--color-bg-menu); */
  --color-bg-menu-transparent: rgba(8, 16, 23, 0); /* var(--color-bg-menu-transparent); */
  --color-bg-global: #eeeeee; /* var(--color-bg-global); */
  --color-bg-global-darker: #e1e1e1; /* var(--color-bg-global); */
  --color-bg-global-darker2: #7b7b7b; /* var(--color-bg-global-darker2); */
  --color-bg-global-transparent: rgba(199, 199, 199, 0); /* var(--color-bg-global); */
  --color-text-menu: var(--color-bg-global); /* var(--color-text-menu); */
  --color-text-global: #081017; /* var(--color-text-global); */
  --color-alt: #AAB391; /* var(--color-alt); */
  --color-shadow-neu-right: rgba(156, 152, 143, 0.12);
  --color-shadow-neu-left: #e5e5e5;
  --color-white: white;
  --color-black: black;
  --color-light-red: #ff6468;
  --color-grey: #b0b0b0;
  --color-green: #009100;
}

.bg-menu-color {
  background: var(--color-bg-menu) !important;
  color: white !important;
}

.variable-root {
  --color-menu-1: #4799eb; //lighten($menu_1, 50%);
  --color-menu-2: #9270c2; //lighten(, 50%);
  --color-menu-3: #e25a91; //lighten(, 50%);
  --color-menu-4: #e6375a; //lighten(, 50%);
  --color-menu-5: #f77a22; //lighten(, 50%);
  --color-menu-6: #f9af1a; //lighten(, 50%);
  --color-menu-7: #49b66d; //lighten(, 50%);
  --color-menu-8: #3cb8c3; //lighten(, 50%);
  --color-menu-9: #c9c9c9; //lighten(, 50%);
  --color-menu-10: #939393; //lighten(, 50%);
  --color-menu-11: #232323; //lighten(, 50%);
}

.variable-root.dark {
  --color-menu-1: #2d6293; //lighten($menu_1, 50%);
  --color-menu-2: #523f6d; //lighten(, 50%);
  --color-menu-3: #7f3252; //lighten(, 50%);
  --color-menu-4: #862136; //lighten(, 50%);
  --color-menu-5: #924614; //lighten(, 50%);
  --color-menu-6: #9c6f12; //lighten(, 50%);
  --color-menu-7: #28633c; //lighten(, 50%);
  --color-menu-8: #22696f; //lighten(, 50%);
  --color-menu-9: #9a9a9a; //lighten(, 50%);
  --color-menu-10: #595959; //lighten(, 50%);
  --color-menu-11: #232323; //lighten(, 50%);

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  .badge-danger {
    background-color: #9b2330;
  }

  .badge-warning {
    background-color: #c79506;
  }

  table {
    color: var(--color-black);
  }

  .btn-outline-dark {
    color: var(--color-black);
    border-color: var(--color-black);
  }

  .dropdown-menu, .dropdown-item {
    background: var(--color-white);
    color: var(--color-black);
  }

  .dropdown-item:hover {
    background: var(--color-grey)
  }
}

.red {
  color: red;
}

hr.lg {
  border-top-width: 3px;
}

.variable-root.dark a.nav-link {
  color: var(--color-text-menu)
}

.dropdown {
  display: inline-block;
}


.variable-root .modal-content {
  background: var(--color-bg-global);
  color: var(--color-text-global);
}

.btn-primary, .btn-primary:focus {
  background-color: #FF9900;
  color: black;
  border: 1px solid #FF9900;
  border-radius: 4px;
}

.btn {
  //border-radius: 6px !important;
  //padding-left: 20px;
  //padding-right: 20px;
}

//.btn-group .btn {
//  margin-right: 10px !important;
//}
.btn-secondary {
  background-color: #ffd7a7;
  //background: linear-gradient(to bottom, #f7e1ae, #fbfbfb);
  color: black;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 4px;
  box-shadow: 0px 0px 0px var(--color-black);
}

.bg-success-warning {
  background: #94B426;
}

.bg-warning-danger {
  background: #EE7B26;
}


.btn-primary:hover, .btn-secondary:hover {
  background-color: #bf7000 !important;
  border: 1px solid #bf7000;
  outline: transparent !important;
  box-shadow: 0px 0px 3px var(--color-black) !important;
}

.global-colors {
  background-color: var(--color-bg-global);
  color: var(--color-text-global);
  transition: background-color 1s, color 1s;
}

.force-global-colors {
  background-color: var(--color-bg-global) !important;
  color: var(--color-text-global) !important;
  transition: background-color 1s, color 1s !important;
}

.force-global-colors-transparent-bg {
  background-color: transparent !important;
  color: var(--color-text-global) !important;
  transition: background-color 1s, color 1s !important;
}

input, .input-global-colors {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  transition: background-color 1s, color 1s, box-shadow 0.2s !important;
  //border: 0 !important;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid var(--color-black) !important;;
  box-shadow: inset 2px 2px 4px var(--color-shadow-neu-right),
  inset -2px -2px 4px var(--color-shadow-neu-left);
}


input.need_to_be_filled, .input-global-colors.need_to_be_filled {
  box-shadow: inset 2px 2px 4px var(--color-shadow-neu-right),
  inset -2px -2px 4px var(--color-shadow-neu-left), 0 0 10px #ffc107;
}

.highlight {
  transition: 0.2s box-shadow, 0.2s background-color;
}

.highlight.highlighted {
  box-shadow: 0 0 10px black !important;
  background: black;
  color: white;
  transition: 0.2s box-shadow;
}

input:focus, .input-global-colors:focus, .input-global-colors.focus {
  box-shadow: inset 4px 4px 8px var(--color-shadow-neu-right),
  inset -4px -4px 8px var(--color-shadow-neu-left);
  outline: 5px double blue;
}


.menu-colors {
  background-color: var(--color-bg-menu) !important;
  color: var(--color-text-menu) !important;;
  transition: background-color 1s, color 1s;
}

.menu-colors .btn-outline-primary,
.menu-colors .btn-link,
.menu-colors .btn-danger {
  color: var(--color-text-menu) !important;;
}

.variable-root.dark .btn-link, .variable-root.dark .external_link {
  color: #fff;
}

.variable-root.dark .btn-outline-primary {
  color: #fff;
  border-color: #fff;
}

.variable-root.dark .alert-primary {
  color: #c4c4ed;
  background-color: #00005a;
  border-color: #6c6c86;
}

.variable-root.dark .alert-warning {
  color: #ffd764;
  background-color: #453400;
  border-color: #a49c84;
}

.variable-root.dark .alert-success {
  color: #00d631;
  background-color: #00470c;
  border-color: #00680c;
}

.variable-root.dark .alert-danger {
  color: #fb6371;
  background-color: #5f0009;
  border-color: #c68e93;
}

.btn-link.black {
  color: var(--color-black);
}


.black_and_white {
  background-color: var(--color-white);
  color: var(--color-black);
}

.half_opacity {
  opacity: 0.3;
}

.half_opacity:hover {
  opacity: 1;
}

* {
  scroll-behavior: smooth;
}

body {
  font-family: 'Lato', 'Twemoji Mozilla', sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin: 0;
  padding: 0;
  background: var(--color-bg-global);
  color: var(--color-text-global);
}


label {
  font-weight: 300;
}


.btn {
  font-size: 20px;
}

.btn-sm {
  font-size: 14px;
}

.btn-lg {
  font-size: 26px;
}

.btn-sm.smaller, .smallest {
  font-size: 12px;
}

.Toastify__toast-body {
  font-weight: bold;
  color: #464646
}

.loading {
  animation: 1s glow infinite;
}

.smaller {
  font-size: 80%;
}

@keyframes glow {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.spin {
  animation: 1s spin infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.badge {
  max-width: 100%;
  overflow: hidden;
}

.green {
  color: green;
  font-weight: bold;
}


.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-wrap: normal;
}

.chrome-picker input {
  background: white;
}


.min-vw-md-100 {
  min-width: 512px;
}

@media (max-width: 512px) {
  .min-vw-md-100 {
    min-width: 100vw !important;
  }
}

@media (max-width: 1280px) {
  .modal-dialog {
    max-width: 90vw;
  }

}

.dropdown-toggle.btn.btn-link {
  padding: 0;
}

.svg_overflow_visible svg {
  overflow: visible !important;
}

.table th, .table td {
  border-top: transparent;
}


span.step-number {
  color: var(--color-white);
  background: var(--color-black);
  font-size: 20px;
  text-align: center;
  line-height: 22px;
  width: 30px;
  height: 30px;
  font-weight: bold;
  padding: 5px;
  display: inline-block;
  border-radius: 100%;
}


.tippy-popper {
  pointer-events: all;
}

.keyword {
  &.automatically_added, &.used {
    border: 2px solid var(--color-black);
  }

  &.unused {
    opacity: 0.7
  }
}

input[disabled] {
  opacity: 0.3
}

ul.no-bullets {
  list-style-type: none;
}

.tippy-popper {
  z-index: 9999999 !important;
}

.modal-backdrop {
  z-index: 999998;
}

.modal-backdrop.show {
  opacity: 0.7;
}


.h1.highlight {
  background: #FF990099;
  padding: 10px;
}

.table thead th, .table tbody tr.border-bottom {
  border-bottom: 1px solid #dee2e6;
}

.clickable {
  transition: 0.2s opacity;
}
.clickable:hover {
  opacity: 1!important;
}

.chrome-picker input {
  padding: 0;
}

