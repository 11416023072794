.progress_badge {
    border-radius: 5px;
}
.progress_badge.progress_badge-success {
    background: green;
    color: white;
}
.progress_badge.progress_badge-warning {
    background: #FF9900;
    color: black;
}
.progress_badge.progress_badge-danger {
    background: #dc3545;
    color: white;
}
