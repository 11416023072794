.DraftEditor-root {
    font-family: 'Lato', 'Twemoji Mozilla', sans-serif !important;
    font-weight: 400;
    font-size: 20px;
}

textarea.field {
    padding: 9px;
    line-height: 22px;
}
.texteditor-container.no-control .EditorToolbar__root___3_Aqz {
    display: none
}
