
.controller .control:hover {
    border: 1px solid grey;
    opacity: 0.2;
}

.controller .control.is_selected {
    opacity: 1;
    z-index: 9999;
}

.controller .group_control.is_selected {
    opacity: 1;
    z-index: 0;
}


.controller .control .handle.round {
    border: 1px solid grey;
    border-radius: 100%;
    background: white;
}

.controller .control .handle.rectangle {
    border: 1px solid grey;
    border-radius: 2px;
    background: white;
}


.controller .control .handle.top_right, .controller .control .handle.bottom_left {
    cursor: nesw-resize;
}

.controller .control .handle.top_left, .controller .control .handle.bottom_right {
    cursor: nwse-resize;
}

.controller .control .handle.global {
    cursor: move;
}

.controller .control .handle.bottom, .controller .control .handle.top {
    cursor: ns-resize
}

.controller .control .handle.left, .controller .control .handle.right {
    cursor: ew-resize
}

.controller .control .handle.rotate {
    cursor: move;
    border: 1px solid grey;
    border-radius: 2px;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
    color: black;
}

.controller .control .handle.edit {
    border: 1px solid grey;
    border-radius: 2px;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
    color: black;
}
