.column_label.rotate {
    transform-origin: 0 100%;
    transform: rotate(-45deg);
    width: 500px;
}

.col_content .hidden {
    position: absolute;
    transform-origin: 0 100%;
    display: none;
    z-index: 10;
    padding: 10px;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%) translateY(-110%);
    top: 0;
    width: 200px;
    text-align: center;
}

