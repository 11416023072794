.competitor_selector {
    transition: opacity 0.3s, background-color 0.3s;
    padding-top: 3px;
    color: var(--color-black);
    border: 2px solid red;
    opacity: 0.5;
}
.competitor_selector:hover {
    opacity: 1 !important;
}
.competitor_selector.is_selected {
    background: rgb(3, 58, 0);
    border: 2px solid green;
    color: white;
    opacity: 1;
}
