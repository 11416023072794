.emoji_selector {
  transition: transform 0.2s, background-color 0.2s;
  border-radius: 100%;
  transform-origin: 50% 100%;
  color: var(--color-black) !important;
}

.emoji_selector:hover {
  transform: scale(3);
  background-color: var(--color-white);
  z-index: 42;
}


.highlight-keyword {
  //style="font-weight: bolder; padding: 3px; border-radius: 3px"
  //font-weight: bolder;
  padding: 3px;
  border-radius: 3px;

  &.highlight-keyword-danger {
    background: #dc354533;
  }

  &.highlight-keyword-warning {
    background: #ffc10733;
  }

  &.highlight-keyword-success {
    background: #28a74533;
  }

  &.highlight-keyword-secondary {
    background: rgba(139, 139, 139, 0.2);
  }

  &.highlight-keyword-dark {
    background: rgba(0, 0, 0, 0.2);
  }

}
