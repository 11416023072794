.book-container {
    display: flex;
    align-items: center;
    justify-content: center;
    perspective: 600px;
}

.book {
    width: 200px;
    height: 300px;
    position: relative;
    transform-style: preserve-3d;

}

.book > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #01060f;
    border-radius: 0 2px 2px 0;
    z-index: 3;
}

.book-before {
    position: absolute;
    z-index: 2;
    content: ' ';
    background-color: blue;
    background: linear-gradient(90deg,
    #fff 0%,
    #f9f9f9 5%,
    #fff 10%,
    #f9f9f9 15%,
    #fff 20%,
    #f9f9f9 25%,
    #fff 30%,
    #f9f9f9 35%,
    #fff 40%,
    #f9f9f9 45%,
    #fff 50%,
    #f9f9f9 55%,
    #fff 60%,
    #f9f9f9 65%,
    #fff 70%,
    #f9f9f9 75%,
    #fff 80%,
    #f9f9f9 85%,
    #fff 90%,
    #f9f9f9 95%,
    #fff 100%
    );

    border: 1px solid #f2f2f2;
}

.book-after {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    background-color: #01060f;
    border-radius: 0 2px 2px 0;
}
