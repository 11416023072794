.update_selector_type {
    font-size: 32px;
    text-align: center;
    cursor: pointer;
    opacity: 0.2;
}

.update_selector_type:hover {
    opacity: 0.5;
}

.update_selector_type.is_selected {
    opacity: 1;
}
