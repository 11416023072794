.dom_renderer_text .content p:last-child,
.dom_renderer_text .content ul:last-child,
.dom_renderer_text .content ol:last-child {
    margin-bottom: 0 !important;
}


.dom_renderer_text .emoji {
    font-family: 'Twemoji Mozilla', sans-serif;
}
