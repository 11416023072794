
.flat_choice {

  .inner {
    border-radius: 5px;
    transition: 0.2s background-color;

    background: var(--color-white);
    color: var(--color-black);
    &.is_current, &:hover {
      background: var(--color-grey);
      color: var(--color-black);
    }
  }

}
