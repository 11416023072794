.product_menu {
  .product_menu_item {
    background: var(--color-bg-menu);
    color: var(--color-text-menu);

    &:hover, &.current {
      background: var(--color-bg-global);
      color: var(--color-text-global);
      z-index: 10;
    }

    &:hover {
      z-index: 11;
    }
  }

  .product_menu_header {

    border-radius: 10px;

    .color_background {
      transition: opacity 0.2s;
    }
    &:hover .color_background {
      opacity: 1 !important;
      color: white !important;
    }
  }

  .product_menu_label {
    line-height: 18px;
    font-weight: 400;
  }

  .product_menu_items_container {

    background: var(--color-bg-menu);
    color: var(--color-text-menu);
  }

  .color_bar {
    background: var(--color-bg-menu);
    height: 20px;

    &.top {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
    }

    &.bottom {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .color {
    transition: width 0.2s;
    height: 100%;
    width: 12px;
    left: 0;
    top: 0;
    opacity: 0.4;

    &:hover, &.active {
      width: 100%
    }
  }

  .product_menu_item:hover .color {
    width: 100%
  }

  .sub_item:hover  {
    font-weight: bold;
  }
}
