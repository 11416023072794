
.pricing_tool table thead {
    border-bottom: 2px dotted black;
}

.pricing_tool .margin_tr_20 {
    border-top: 2px dotted black;
}

.pricing_tool .margin_tr_30 {
    border-top: 2px dotted black;
    border-bottom: 2px dotted black;
    font-weight: bold;
}
.pricing_tool .current {
    border: 2px solid black;
    font-weight: bold;
}

.pricing_tool .margin_tr_50 {
    border-bottom: 2px dotted black;
}


.pricing_tool table.calcul td {
    padding: 5px;
}
