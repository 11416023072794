
.keyword_highlighter .keyword, .keyword_highlighter .keyword.selected {
    color: transparent;
}
.keyword_highlighter .keyword.selected {
    background:var(--green);
    border-radius:.25rem;
}

.keyword_highlighter .public-DraftStyleDefault-block {

}
