.list-item {
  padding: 10px;
  border-radius: 6px;
  transition: box-shadow 0.2s, color 0.2s, background-color 0.2s, border 0.2s;
  background-color: var(--color-white);
  color: var(--color-black);
  border: 1px solid transparent;


  &.no-border, &.clickable.no-border {
    border: none !important;
    box-shadow: none;
  }

  &.clickable {
    border: 1px solid transparent;
  }

}

.variable-root.dark .list-item {

  border: 1px solid #2f2f2f;
  box-shadow: none;
  margin-bottom: 10px;


  &.active, &.clickable:hover {
    border: 1px solid var(--color-green);
  }
}

.variable-root.light .list-item {

  border: 1px solid transparent;
  box-shadow: 0px 0px 10px var(--color-shadow-neu-right),
  -0px -0px 10px var(--color-shadow-neu-right);


  &.active, &.clickable:hover {
    border: 1px solid var(--color-green);
    box-shadow: 3px 3px 6px var(--color-shadow-neu-right),
    -3px -3px 6px var(--color-shadow-neu-left);
  }

}

.gradient_bg, .bg-gradient_bg {
  background: rgb(59, 105, 120);
  background: linear-gradient(175deg, rgba(59, 105, 120, 1) 0%, rgba(32, 64, 81, 1) 100%);
  color: white;
}

.bg-gradient_bg-flat {
  background: rgba(59, 105, 120, 1);
  color: white;
}

.gradient_bg_amazon, .bg-gradient_bg_amazon {
  background: rgb(59, 105, 120);
  background: linear-gradient(175deg, #e88a39 0%, rgba(32, 64, 81, 1) 200%);
  color: white;
}

.bg-gradient_bg_amazon-flat {
  background: #e88a39;
  color: white;
}

.gradient_bg_profit, .bg-gradient_bg_profit {
  background: rgb(59, 105, 120);
  background: linear-gradient(175deg, #edffea 0%, rgba(32, 64, 81, 1) 200%);
  color: black !important;
}

.bg-gradient_bg_profit-flat {
  background: #edffea;
  color: black !important;
}

.gradient_bg_real_profit, .bg-gradient_bg_real_profit {
  background: rgb(59, 105, 120);
  background: linear-gradient(175deg, #85bb65 0%, rgba(32, 64, 81, 1) 200%);
  color: white !important;
}

.bg-gradient_bg_real_profit-flat {
  background: #85bb65;
  color: white !important;
}

.bg-gradient_bg_loss-flat {
  background: #bb6565;
  color: white !important;
}

.list-item-shadow {
  box-shadow: 0px 0px 10px var(--color-shadow-neu-right),
  -0px -0px 10px var(--color-shadow-neu-right);
  border-radius: 14px;
}

.list-item.deleted {
  background-color: var(--color-light-red);
}

.list-item.compact {
  padding: 10px;
  border-radius: 4px;
}

.neu_shadows {
  box-shadow: 3px 3px 6px var(--color-shadow-neu-right),
  -3px -3px 6px var(--color-shadow-neu-left);
}


.list-item:hover {

}


.clickable {
  cursor: pointer;
}
