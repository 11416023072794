.left-menu-container,
.left-menu-container .left-menu-fixed-width {
  width: 78px;
}

.left-menu:hover,
.left-menu-opened-width,
.left-menu-container.force-full-width,
.left-menu-container.force-full-width .left-menu {
  width: 264px;
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

.left-menu {
  background: var(--color-bg-menu);
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.2s, background 3s;
  z-index: 15;
  overflow: hidden;

  &:hover {
    z-index: 15;
  }
}

.left-menu, .left-menu-container .left-menu a, .left_menu_like {
  color: var(--color-text-menu);
  transition: 0.2s width, 1s color, background 1s;
  text-decoration: none;
}

.left_menu_like {

  transition: 0.2s color, 0.2s background;
}

.left-menu .intellifox-container {
  color: white;
}

.show_only_on_hover {
  opacity: 0;
  transition: opacity 0.2s;
}

.show_only_when_closed {
  opacity: 1;
  transition: opacity 0.2s;
}

.left-menu:hover .show_only_on_hover, .left-menu-container.force-full-width .show_only_on_hover {
  opacity: 1;
}

.left-menu:hover .show_only_when_closed, .left-menu-container.force-full-width .show_only_when_closed {
  opacity: 0;
}

.left-menu .scrollable-menu {
  overflow: hidden;
}

.left-menu:hover .scrollable-menu, .left-menu-container.force-full-width .scrollable-menu {
  overflow-y: auto;
  overflow-x: hidden;
}

.left-menu-container .left-menu .item-container:hover,
.left-menu-container .left-menu .intellifox-container:hover,
.left_menu_like.is_current,
.left_menu_like:hover,
.left_menu_active_theme {
  background: var(--color-text-menu) !important;
  color: black !important;
  transition: 0.2s color, 0.2s background;
}

.left-menu .item-container.is-active,
.left-menu .item-container.is-active:hover {
  background: var(--color-text-menu) !important;
  color: white !important;
  transition: 0.2s color, 0.2s background;
}


.left-menu .item-label {
  opacity: 0;
  transition: opacity 0.2s;
}

.avoid-left-menu {
  padding-left: 120px !important;
}


.left-menu-container.force-full-width .left-menu .item-label,
.left-menu-container.force-fullest-width .left-menu .item-label,
.left-menu:hover .item-label {
  opacity: 1;
}

.left-menu-container.force-fullest-width,
.left-menu-container.force-fullest-width .left-menu {
  width: 100%;
}

.intellifox-icon, .item-icon {
  transition: transform 0.5s;
}
